import { trackPromise } from "react-promise-tracker";

const ibLocal = false;
const ibBaseUrl = ibLocal
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure/ignition-backend`;

const dmvLocal = false;
const dmvBaseUrl = dmvLocal
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure`;

/**
 * Retrieve the session data for a given ignition token
 * @param ignitionToken - The unique token for the Ignition session.
 * @returns an Ignition Session object.
 */
export const getSession = async (ignitionToken) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/session/${ignitionToken}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
      })
    );

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    let data = body.data;
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It makes a GET request to the DMV API to get a client's information
 * @param clientId - The id of the client you want to get.
 * @returns The client object
 */
export const getClient = async (clientId) => {
  try {
    const res = await trackPromise(
      fetch(`${dmvBaseUrl}/clients/${clientId}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
    );

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    let data = body.data;
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It makes a GET request to the Ignition API to get the configuration of a client
 * @param clientId - The client ID of the client you want to get the ignition config for.
 * @returns the data from the response body.
 */
export const getClientWithIgnitionConfig = async (clientId) => {
  try {
    const res = await trackPromise(
      fetch(`${dmvBaseUrl}/clients/${clientId}/ignition-config`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
    );

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    let data = body.data;
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * `getAccount` is a function that takes in a carrier, username, and password and returns a JSON object
 * with the account information
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The name of the carrier you want to get the account for.
 * @param username - The username of the account you want to retrieve.
 * @param password - The password for the account.
 */
export const login = async (
  ignitionToken,
  carrier,
  username,
  password,
  zipcode,
  connectionId
) => {
  try {
    const res = await fetch(`${ibBaseUrl}/login`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        carrier,
        username,
        password,
        zipcode,
        connectionId,
      }),
    });

    return res;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes an ignition token and returns an account object
 * @param ignitionToken - The token you received from the user when they logged in.
 * @returns the data object from the body of the response.
 */
export const getAccount = async (ignitionToken) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/accounts`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * It returns a list of available MFA options for the user.
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The name of the carrier.
 */
export const getMfaOptions = async (ignitionToken, carrier) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/mfa/options`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        carrier,
      }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * Send an MFA code to the user
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The carrier that the user is trying to sign up with.
 * @param type - The type of MFA that you want to send.
 * @param id - The id of the MFA device
 */
export const sendMfa = async (ignitionToken, carrier, type, id) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/mfa/send`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        carrier,
        type,
        id,
      }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * It takes in a carrier, username, type, and code and sends it to the API
 * @param ignitionToken - The unique token for the Ignition session.
 * @param carrier - The carrier that the user is trying to log in to.
 * @param type - The type of MFA code you are entering.
 * @param code - The code that you received from the MFA device.
 * @returns The data object is being returned.
 */
export const enterMfa = async (ignitionToken, carrier, type, code) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/mfa/enter`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        carrier,
        type,
        code,
      }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * It links a policy to an account.
 * @param ignitionToken - The unique token for the Ignition session.
 * @param policyNumber - The policy number of the policy you want to link to the account.
 */
export const linkPolicy = async (ignitionToken, policyType, policyNumber) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/policies`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        policyType,
        policyNumber,
      }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

export const linkDocuments = async (
  ignitionToken,
  policyType,
  policyNumber,
  policy
) => {
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/documents`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        policyType,
        policyNumber,
        policy,
      }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * This function stubs an account for a carrier.
 * @param ignitionToken - The token you received from the login call.
 * @param account - {
 * @returns The stubbed account
 */
export const stubAccount = async (ignitionToken, account) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/stub/accounts`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-ignition-token": ignitionToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        // Need to standardize these for all carriers...
        body: JSON.stringify(account),
      })
    );

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    let data = body.data;
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes a policy object and returns a stubbed policy object
 * @param policy - This is the policy object that you want to stub.
 * @returns The stubbed policy is being returned.
 */
export const stubPolicy = async (ignitionToken, policy) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/stub/policies`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-ignition-token": ignitionToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        // Need to standardize these for all carriers...
        body: JSON.stringify(policy),
      })
    );

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    let data = body.data;
    return data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes an ignition token, policy number, filename, and filetype, and returns a document object
 * @param ignitionToken - The token you received from the login function
 * @param policy - The policy number you want to add the document to.
 * @param filename - The name of the file you want to upload.
 * @param filetype - The filetype of the document.
 * @returns The document ID
 */
export const updatePolicy = async (
  ignitionToken,
  policy,
  account,
  documents
) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/policies/${policy}`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-ignition-token": ignitionToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify({
          account,
          documents,
        }),
      })
    );
    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes an ignition token, a policy ID, and a policy object, and then it updates the policy with
 * the new policy object
 * @param ignitionToken - The token you received from the login function
 * @param policyId - The ID of the policy you want to update.
 * @param policy - This is the policy object that you want to update.
 * @returns The policy elements are being returned.
 */
export const upsertPolicyElements = async (ignitionToken, policyId, policy) => {
  try {
    const res = await fetch(`${ibBaseUrl}/policies/${policyId}`, {
      method: "put",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify(policy),
    });

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * Validate if a policy meets certain requirements.
 * @param ignitionToken - token for the ignition session
 * @param policyId - policy ID for the policy you'd like to validate
 * @param validationType - validation type - check backend documentation for supported validation types
 * @returns validation results based on type
 */
export const validatePolicy = async (
  ignitionToken,
  policyId,
  validationType
) => {
  console.log(policyId);
  const res = await trackPromise(
    fetch(`${ibBaseUrl}/policies/${policyId}/validate`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({ type: validationType }),
    }),
    "carrier-loader"
  );

  let body = await res.json();

  if (!res.ok) {
    const err = new Error(body.message);
    err.code = res.status;
    throw err;
  }

  let data = body.data;
  return data;
};

/**
 * It takes an ignition token and a params object, and returns the updated ignition
 * @param ignitionToken - The token that was returned from the createIgnition call.
 * @param params - {
 * @returns the data from the body of the response.
 */
export const updateIgnition = async (ignitionToken, params) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/ignition/${ignitionToken}`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify(params),
      })
    );
    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes in a params object, makes a POST request to the Ignition API, and returns the data from the
 * response
 * @param params - {
 * @returns A promise that resolves to the data object from the response body.
 */
export const createIgnition = async (params) => {
  try {
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/ignition`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify(params),
      })
    );
    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes an account and client, and returns an authCode
 * @param account - The account ID of the user you want to get an auth code for.
 * @param client - The client ID of the application you're using.
 * @returns The authCode is being returned.
 */
export const getAccessToken = async (
  account,
  policies,
  client,
  ignitionToken
) => {
  // We need to show some error to the consumer if this fails
  try {
    // Get auth code for session and account
    const res = await trackPromise(
      fetch(`${ibBaseUrl}/accessTokens`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "x-ignition-token": ignitionToken,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify({
          client,
          policies,
          account,
        }),
      })
    );
    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }

    // Return authCode
    let authCode = body.data.authCode;
    return authCode;
  } catch (error) {
    throw Error(error.message);
  }
};

/**
 * It takes in an Ignition token, a policy, a filename, and a filetype, and returns a signed URL
 * @param ignitionToken - The token you get from the `/auth` endpoint
 * @param policy - The policy number you want to upload the document to.
 * @param filename - The name of the file you want to upload
 * @param filetype - The file type of the file you're uploading.
 * @returns The signed URL for the file to be uploaded.
 */
export const generateSignedUrl = async (ignitionToken, policy, filepath) => {
  // We need to show some error to the consumer if this fails
  try {
    // Get auth code for session and account
    const res = await fetch(`${ibBaseUrl}/generateSignedUrl`, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        policy,
        filepath,
      }),
    });

    let body = await res.json();

    if (!res.ok) {
      throw Error(body.message);
    }
    return body.data;
  } catch (error) {
    throw Error(error.message);
  }
};

export const processDocuments = async (
  ignitionToken,
  documentKey,
  documentsObject
) => {
  try {
    const res = await fetch(`${ibBaseUrl}/documents/process`, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-ignition-token": ignitionToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
      body: JSON.stringify({
        documentKey,
        documentsObject,
      }),
    });

    let body = await res.json();

    if (!res.ok) {
      const err = new Error(body.message);
      err.code = res.status;
      throw err;
    }

    let data = body.data;
    return {
      success: true,
      data,
    };
  } catch (error) {
    throw error;
  }
};
